import { toast } from 'react-toastify';
import { Method } from '../types';
import { createRequest } from '../utils/createRequest';
import { useAuth } from './useAuth';

export function useRequest() {
  const { accessToken } = useAuth();

  return <R, P = unknown>(url: string, method: Method, payload?: P) =>
    createRequest<R, P>(url, method, accessToken, payload).catch((error) => {
      return error?.json().then((e: any) => {
        toast.error(e.message);
        throw error;
      });
    });
}
